import { RouteInfo } from './sidebar.metadata';



export const ROUTES_ADMIN: RouteInfo[] = [

  {
    path: '',
    title: 'MENUITEMS.MAIN.TEXT',
    iconType: '',
    icon: '',
    class: '',
    groupTitle: true,
    badge: '',
    badgeClass: '',
    submenu: [],
  },
  {
    path: '',
    title: 'MENUITEMS.REPORTS.TEXT',
    iconType: 'feather',
    icon: 'bar-chart',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [
      {
        path: 'report/report3',
        title: 'MENUITEMS.REPORTS.LIST.REPORT3',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },

    ],
  },

];
